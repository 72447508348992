import React, { FC, useRef, useEffect } from "react";
import { IFloorPlanProps } from "../../../interfaces/floor-plan-props";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";

import {
  FloorPlanItemWrapper,
  FloorPlanType,
  FloorPlanItemImage,
  FloorPlanLabel,
  FloorPlanItemDetails,
  FloorPlanTypeNum,
  FloorPlanTypeBtn,
  SliderWrapper,
  LeftArrow,
  RightArrow,
} from "./index.styled";

import leftArrow from "../../../images/icon-arrow-left-dark.svg";
import rightArrow from "../../../images/icon-arrow-right-dark.svg";

function NextArrow(props: { onClick: any }) {
  const { onClick } = props;
  return (
    <RightArrow onClick={onClick} className="slider-arrow">
      <img src={rightArrow} alt="Arrow Right" />
    </RightArrow>
  );
}

function PrevArrow(props: { onClick: any }) {
  const { onClick } = props;
  return (
    <LeftArrow onClick={onClick} className="slider-arrow">
      <img src={leftArrow} alt="Arrow Left" />
    </LeftArrow>
  );
}

const FloorPlanItem: FC<IFloorPlanProps> = ({ ...props }) => {
  const floorPlanItemWrapperRef = useRef<HTMLDivElement>(null);
  const floorPlanTypeRef = useRef<HTMLDivElement>(null);
  const floorPlanItemDetailsRef = useRef<HTMLDivElement>(null);
  const sliderRef: any | null = useRef(null);
  const floorPlansSliderWrapperRef = useRef<HTMLDivElement>(null);

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    autoplay: false,
    nextArrow: <NextArrow onClick={props.onClick} />,
    prevArrow: <PrevArrow onClick={props.onClick} />,
  };

  useEffect(() => {
    ScrollTrigger.create({
      trigger: floorPlanItemWrapperRef?.current || "",
      toggleActions: "play none none none",
      start: "top+=40% bottom",
      animation: gsap
        .timeline()
        .fromTo(
          floorPlanTypeRef?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          "+=0.15"
        )
        .fromTo(
          floorPlansSliderWrapperRef?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          "+=0.15"
        )
        .fromTo(
          floorPlanItemDetailsRef?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          "+=0.15"
        ),
    });
  }, []);

  return (
    <FloorPlanItemWrapper ref={floorPlanItemWrapperRef}>
      <SliderWrapper ref={floorPlansSliderWrapperRef}>
        <Slider {...settings} ref={sliderRef}>
          <FloorPlanItemImage>
            <FloorPlanType ref={floorPlanTypeRef}>
              <FloorPlanTypeNum>{props.id}</FloorPlanTypeNum>
              {props.pdf && <FloorPlanTypeBtn href={props.pdf} target="_blank">
                VIEW PLAN
              </FloorPlanTypeBtn>}
            </FloorPlanType>
            <img src={props.image} alt="Floor Plan" />
            {props.image2 && <FloorPlanLabel>LOWER LEVEL</FloorPlanLabel>}
          </FloorPlanItemImage>
          {props.image2 && (
            <FloorPlanItemImage>
              <FloorPlanType ref={floorPlanTypeRef}>
                <FloorPlanTypeNum>{props.id}</FloorPlanTypeNum>
                <FloorPlanTypeBtn href={props.pdfUL} target="_blank">
                  VIEW PLAN
                </FloorPlanTypeBtn>
              </FloorPlanType>
              <img src={props.image2} alt="Floor Plan" />
              <FloorPlanLabel>UPPER LEVEL</FloorPlanLabel>
            </FloorPlanItemImage>
          )}
        </Slider>
      </SliderWrapper>
      <FloorPlanItemDetails ref={floorPlanItemDetailsRef}>
        <div>
          <p>{props.bedrooms} BED</p>
          <p>
            {props.bathrooms} BATH / {props.pwdr} PWDR
          </p>
        </div>
        <div>
          <p>INTERIOR</p>
          <p>{props.interior} SQFT</p>
        </div>
        <div>
          <p>EXTERIOR</p>
          <p>{props.exterior} SQFT</p>
        </div>
      </FloorPlanItemDetails>
    </FloorPlanItemWrapper>
  );
};

export default FloorPlanItem;
