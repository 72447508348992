import React, { useContext, useEffect } from "react";
import Footer from "../components/footer";
import FloorPlanItems from "../components/floor-plans";
import FirstLoadContext from "../context";

// import floorPlan1 from "../images/floorPlans/Floorplan_1.svg";
// import floorPlan1A from "../images/floorPlans/Floorplan_1A.svg";
// import floorPlan1AR from "../images/floorPlans/Floorplan_1AR.svg";
import floorPlan1AR from "../images/floorPlans/v1/Floorplan_1AR_v1.svg";
// import floorPlan1R from "../images/floorPlans/Floorplan_1R.svg";
// import floorPlan2 from "../images/floorPlans/Floorplan_2.svg";
// import floorPlan2A from "../images/floorPlans/Floorplan_2A.svg";
// import floorPlan2AR from "../images/floorPlans/Floorplan_2AR.svg";
// import floorPlan2B from "../images/floorPlans/Floorplan_2B.svg";
import floorPlan2BR from "../images/floorPlans/v1/Floorplan_2BR_v1.svg";
// import floorPlan2R from "../images/floorPlans/Floorplan_2R.svg";
// import floorPlan3_1 from "../images/floorPlans/Floorplan_3_1.svg";
// import floorPlan3_2 from "../images/floorPlans/Floorplan_PH3_2.svg";
// import floorPlan3A_2 from "../images/floorPlans/Floorplan_PH3A_2.svg";
// import floorPlan3R_1 from "../images/floorPlans/v1/Floorplan_PH3R-LL_v1.svg";
// import floorPlan3R_2 from "../images/floorPlans/v1/Floorplan_PH3R-UL_v1.svg";
import floorPlan3AR_1 from "../images/floorPlans/v1/Floorplan_PH3AR-LL_v1.svg";
import floorPlan3AR_2 from "../images/floorPlans/v1/Floorplan_PH3AR-UL_v1.svg";
// import floorPlan4_1 from "../images/floorPlans/Floorplan_4_1.svg";
// import floorPlan4_2 from "../images/floorPlans/Floorplan_PH4_2.svg";
// import floorPlan4A_2 from "../images/floorPlans/Floorplan_PH4A_2.svg";
// import floorPlan4R_1 from "../images/floorPlans/v1/Floorplan_PH4R-LL_v1.svg";
// import floorPlan4R_2 from "../images/floorPlans/v1/Floorplan_PH4R-UL_v1.svg";
import floorPlan4AR_2 from "../images/floorPlans/v1/Floorplan_PH4AR-LL_v1.svg";
import floorPlan4AR_1 from "../images/floorPlans/v1/Floorplan_PH4AR-UL_v1.svg";

// import floorPlan1PDF from "../../static/floorPlansPDF/floor-plan-1.pdf";
// import floorPlan1aPDF from "../../static/floorPlansPDF/floor-plan-1A.pdf";
// import floorPlan1rPDF from "../../static/floorPlansPDF/floor-plan-1R.pdf";
import floorPlan1arPDF from "../../static/floorPlansPDF/floor-plan-1AR.pdf";
// import floorPlan2PDF from "../../static/floorPlansPDF/floor-plan-2.pdf";
// import floorPlan2aPDF from "../../static/floorPlansPDF/floor-plan-2A.pdf";
// import floorPlan2arPDF from "../../static/floorPlansPDF/floor-plan-2AR.pdf";
// import floorPlan2bPDF from "../../static/floorPlansPDF/floor-plan-2B.pdf";
import floorPlan2brPDF from "../../static/floorPlansPDF/floor-plan-2BR.pdf";
// import floorPlan2rPDF from "../../static/floorPlansPDF/floor-plan-2R.pdf";
// import floorPlan3ULPDF from "../../static/floorPlansPDF/floor-plan-PH3_UL.pdf";
// import floorPlan3LLPDF from "../../static/floorPlansPDF/floor-plan-PH3_LL.pdf";
// import floorPlan3aULPDF from "../../static/floorPlansPDF/floor-plan-PH3A_UL.pdf";
// import floorPlan3aLLPDF from "../../static/floorPlansPDF/floor-plan-PH3A_LL.pdf";
// import floorPlan3arULPDF from "../../static/floorPlansPDF/floor-plan-PH3AR_UL.pdf";
// import floorPlan3arLLPDF from "../../static/floorPlansPDF/floor-plan-PH3AR_LL.pdf";
 import floorPlan3ar from "../../static/floorPlansPDF/floor-plan-PH3AR.pdf";
 import floorPlan4ar from "../../static/floorPlansPDF/floor-plan-PH4AR.pdf";
// import floorPlan3rULPDF from "../../static/floorPlansPDF/floor-plan-PH3R_UL.pdf";
// import floorPlan3rLLPDF from "../../static/floorPlansPDF/floor-plan-PH3R_LL.pdf";
// import floorPlan4ULPDF from "../../static/floorPlansPDF/floor-plan-PH4_UL.pdf";
// import floorPlan4LLPDF from "../../static/floorPlansPDF/floor-plan-PH4_LL.pdf";
// import floorPlan4aULPDF from "../../static/floorPlansPDF/floor-plan-PH4A_UL.pdf";
// import floorPlan4aLLPDF from "../../static/floorPlansPDF/floor-plan-PH4A_LL.pdf";
// import floorPlan4arULPDF from "../../static/floorPlansPDF/floor-plan-PH4AR_UL.pdf";
// import floorPlan4arLLPDF from "../../static/floorPlansPDF/floor-plan-PH4AR_LL.pdf";
// import floorPlan4rULPDF from "../../static/floorPlansPDF/floor-plan-PH4R_UL.pdf";
// import floorPlan4rLLPDF from "../../static/floorPlansPDF/floor-plan-PH4R_LL.pdf";

const floorPlans = [
  // {
  //   id: "1",
  //   pdf: floorPlan1PDF,
  //   image: floorPlan1,
  //   image2: "",
  //   bedrooms: "3",
  //   bathrooms: "2",
  //   pwdr: "1",
  //   interior: "2,326",
  //   exterior: "100",
  // },
  // {
  //   id: "1R",
  //   pdf: floorPlan1rPDF,
  //   image: floorPlan1R,
  //   image2: "",
  //   bedrooms: "3",
  //   bathrooms: "2",
  //   pwdr: "1",
  //   interior: "2,326",
  //   exterior: "100",
  // },
  // {
  //   id: "1A",
  //   pdf: floorPlan1aPDF,
  //   image: floorPlan1A,
  //   image2: "",
  //   bedrooms: "3",
  //   bathrooms: "2",
  //   pwdr: "1",
  //   interior: "2,052",
  //   exterior: "93",
  // },
  {
    id: "1AR",
    pdf: floorPlan1arPDF,
    image: floorPlan1AR,
    image2: "",
    bedrooms: "3",
    bathrooms: "2",
    pwdr: "1",
    interior: "2,052",
    exterior: "93",
  },
  // {
  //   id: "2",
  //   pdf: floorPlan2PDF,
  //   image: floorPlan2,
  //   image2: "",
  //   bedrooms: "3",
  //   bathrooms: "2",
  //   pwdr: "1",
  //   interior: "2,392",
  //   exterior: "186",
  // },
  // {
  //   id: "2R",
  //   pdf: floorPlan2rPDF,
  //   image: floorPlan2R,
  //   image2: "",
  //   bedrooms: "3",
  //   bathrooms: "2",
  //   pwdr: "1",
  //   interior: "2,392",
  //   exterior: "186",
  // },
  // {
  //   id: "2A",
  //   pdf: floorPlan2aPDF,
  //   image: floorPlan2A,
  //   image2: "",
  //   bedrooms: "3",
  //   bathrooms: "2",
  //   pwdr: "1",
  //   interior: "2,861",
  //   exterior: "361",
  // },
  // {
  //   id: "2AR",
  //   pdf: floorPlan2arPDF,
  //   image: floorPlan2AR,
  //   image2: "",
  //   bedrooms: "3",
  //   bathrooms: "2",
  //   pwdr: "1",
  //   interior: "2,861",
  //   exterior: "361",
  // },
  // {
  //   id: "2B",
  //   pdf: floorPlan2bPDF,
  //   image: floorPlan2B,
  //   image2: "",
  //   bedrooms: "3",
  //   bathrooms: "2",
  //   pwdr: "1",
  //   interior: "2,219",
  //   exterior: "182",
  // },
  {
    id: "2BR",
    pdf: floorPlan2brPDF,
    image: floorPlan2BR,
    image2: "",
    bedrooms: "3",
    bathrooms: "2",
    pwdr: "1",
    interior: "2,219",
    exterior: "182",
  },
  // {
  //   id: "PH3",
  //   pdf: floorPlan3LLPDF,
  //   pdfUL: floorPlan3ULPDF,
  //   image: floorPlan3_1,
  //   image2: floorPlan3_2,
  //   bedrooms: "3",
  //   bathrooms: "2",
  //   pwdr: "2",
  //   interior: "2,811",
  //   exterior: "449",
  // },
  // {
  //   id: "PH3R",
  //   pdf: floorPlan3rLLPDF,
  //   pdfUL: floorPlan3rULPDF,
  //   image: floorPlan3R_1,
  //   image2: floorPlan3R_2,
  //   bedrooms: "3",
  //   bathrooms: "2",
  //   pwdr: "2",
  //   interior: "2,811",
  //   exterior: "449",
  // },
  // {
  //   id: "PH4",
  //   pdf: floorPlan4LLPDF,
  //   pdfUL: floorPlan4ULPDF,
  //   image: floorPlan4_1,
  //   image2: floorPlan4_2,
  //   bedrooms: "3",
  //   bathrooms: "2",
  //   pwdr: "2",
  //   interior: "3,047",
  //   exterior: "962",
  // },
  // {
  //   id: "PH4R",
  //   pdf: floorPlan4rLLPDF,
  //   pdfUL: floorPlan4rULPDF,
  //   image: floorPlan4R_1,
  //   image2: floorPlan4R_2,
  //   bedrooms: "3",
  //   bathrooms: "2",
  //   pwdr: "2",
  //   interior: "3,047",
  //   exterior: "962",
  // },
  // {
  //   id: "PH3A",
  //   pdf: floorPlan3aLLPDF,
  //   pdfUL: floorPlan3aULPDF,
  //   image: floorPlan3_1,
  //   image2: floorPlan3A_2,
  //   bedrooms: "3",
  //   bathrooms: "2",
  //   pwdr: "2",
  //   interior: "2,811",
  //   exterior: "449",
  // },
  {
    id: "PH3AR",
    pdf: floorPlan3ar,
    pdfUL: floorPlan3ar,
    image: floorPlan3AR_1,
    image2: floorPlan3AR_2,
    bedrooms: "3",
    bathrooms: "3",
    pwdr: "1",
    interior: "2,455",
    exterior: "318",
  },
  // {
  //   id: "PH4A",
  //   pdf: floorPlan4aLLPDF,
  //   pdfUL: floorPlan4aULPDF,
  //   image: floorPlan4_1,
  //   image2: floorPlan4A_2,
  //   bedrooms: "3",
  //   bathrooms: "2",
  //   pwdr: "2",
  //   interior: "3,047",
  //   exterior: "962",
  // },
  {
    id: "PH4AR",
    pdf: floorPlan4ar,
    pdfUL: floorPlan4ar,
    image: floorPlan4AR_2,
    image2: floorPlan4AR_1,
    bedrooms: "3",
    bathrooms: "2",
    pwdr: "2",
    interior: "2,849",
    exterior: "910",
  },
];

const FloorPlans = () => {
  const { setFirstLoad } = useContext(FirstLoadContext);
  useEffect(() => {
    setFirstLoad(false);
  }, [setFirstLoad]);
  return (
    <>
      <FloorPlanItems floorPlans={floorPlans} />
      <Footer />
    </>
  );
};

export default FloorPlans;
