import styled from "styled-components";
import { mediaMax } from "../../../helpers/MediaQueries";

export const FloorPlanItemWrapper = styled("div")`
  width: 50%;
  padding-top: 45px;
  display: flex;
  flex-direction: column;
  position: relative;

  &:nth-child(2n-1) {
    border-right: 1px solid #535d53;
    ${mediaMax.tabletLandscape`
      border-right: none;
    `}
  }
  ${mediaMax.tabletLandscape`
    width: 100%
  `}
`;

export const FloorPlanType = styled("div")`
  padding: 0 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #535d53;
`;
export const FloorPlanTypeNum = styled("p")`
  font: normal normal 100 37px/45px Optima;
  ${mediaMax.tabletLandscape`
    font: normal normal 100 24px/29px Optima;
  `}
`;
export const FloorPlanTypeBtn = styled("a")`
  font: normal normal 100 17px/23px Optima;
  cursor: pointer;
  transition: 0.4s ease-out;
  text-decoration: none;
  color: #535d53;
  ${mediaMax.tabletLandscape`
    font: normal normal 100 19px/22px Optima;
  `}
  &:hover {
    text-shadow: #9eba9e 1px 0 10px;
    color: #2e342e;
  }
`;

export const SliderWrapper = styled("div")`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const FloorPlanItemImage = styled("div")`
  padding: 0 12% 48px;
  height: 100%;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
`;

export const FloorPlanLabel = styled("p")`
  font: normal italic 700 13px/17px Optima;
  letter-spacing: 1.95px;
  text-align: center;
  padding: 11px 0 0 0;
  color: #9aa49a;
`;

export const FloorPlanItemDetails = styled("div")`
  padding: 17px 7.5%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #889287;
  color: #f5f3f3;
  letter-spacing: 2.4px;
  ${mediaMax.phoneXL`
      font: normal normal 100 12px/14px Optima;
  `}
`;

export const LeftArrow = styled.div`
  left: 3.5%;
`;

export const RightArrow = styled.div`
  right: 3.5%;
`;
