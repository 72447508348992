import React, { FC } from "react";
import FloorPlanItem from "./floor-plan";
import { FloorPlansWrapper } from "./index.styled";
import { IFloorPlansProps } from "../../interfaces/floor-plans-props";

const FloorPlansItems: FC<IFloorPlansProps> = ({ floorPlans }) => {
  return (
    <FloorPlansWrapper>
      {floorPlans.map((item) => {
        return (
          <FloorPlanItem
            id={item.id}
            pdf={item.pdf}
            pdfUL={item.pdfUL}
            image={item.image}
            image2={item.image2}
            bedrooms={item.bedrooms}
            bathrooms={item.bathrooms}
            pwdr={item.pwdr}
            interior={item.interior}
            exterior={item.exterior}
            key={item.id}
          />
        );
      })}
    </FloorPlansWrapper>
  );
};

export default FloorPlansItems;
