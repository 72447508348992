import styled from "styled-components";
import { mediaMax } from "../../helpers/MediaQueries";

export const FooterWrapper = styled("div")`
  width: 100%;
  background-color: #9ea69c;
  padding: 72px 7.5% 72px;
  display: flex;
  justify-content: center;
`;

export const FooterColumn = styled("div")`
  width: 100%;
`;

export const FooterCopyrights = styled("div")`
  padding-bottom: 48px;
  text-align: center;
  color: #434b42;
  font: normal normal 100 14px/20px Optima;
  span {
    text-decoration: underline;
    letter-spacing: 0;
    font-weight: bold;
    color: #434B42;
    text-transform: uppercase;
    cursor: pointer;
  }
  img {
    height: 12px;
    margin-left: 4px;
    margin-top: 2px;
  }
  ${mediaMax.tabletLandscape`
    padding-bottom: 24px;
  `};
`;

export const FooterLogos = styled("div")`
  display: flex;
  justify-content: center;
  img {
    margin: 0 12px;
  }
  a {
    transition: 0.4s ease-out;
    &:hover {
      opacity: 0.75;
    }
  }
`;
