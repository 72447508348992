import React, { FC, useEffect, useRef, useMemo } from "react";
import {
  FooterWrapper,
  FooterColumn,
  FooterCopyrights,
  FooterLogos,
} from "./index.styled";
import logo1 from "../../images/footer-logo1.svg";
import logo2 from "../../images/footer-logo2.svg";
import logo3 from "../../images/footer-logo3.svg";

import { ScrollTrigger } from "gsap/ScrollTrigger";
import gsap from "gsap";

const Footer: FC<any> = ({ openModal }) => {
  const footerWrapperRef = useRef<HTMLDivElement>(null);
  const footerColumnRef = useRef<HTMLDivElement>(null);
  const footerCopyrightsRef = useRef<HTMLDivElement>(null);
  const footerLogosRef = useRef<HTMLDivElement>(null);
  const currentYear = useMemo(() => new Date().getFullYear(), []);

  useEffect(() => {
    ScrollTrigger.create({
      trigger: footerWrapperRef?.current || "",
      toggleActions: "play none none reverse",
      start: "top+=20% bottom",
      animation: gsap
        .timeline()
        .fromTo(
          footerCopyrightsRef?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          "+=.1"
        )
        .fromTo(
          footerLogosRef?.current || null,
          0.5,
          { opacity: 0, y: 30 },
          { opacity: 1, y: 0 },
          "+=.1"
        ),
    });
  });

  return (
    <FooterWrapper ref={footerWrapperRef}>
      <FooterColumn ref={footerColumnRef}>
        <FooterCopyrights ref={footerCopyrightsRef}>
          © {currentYear} Parkhouse&nbsp;Residences | Real Estate Consulting, Sales and
          Marketing by Polaris Pacific,
          <br /> A Licensed California, Arizona, Colorado, Hawaii, Oregon and
          Washington Broker — CA DRE #01499250
          | <span onClick={openModal}>Legal</span>
          <img src={logo2} alt="EHO" />
        </FooterCopyrights>
        <FooterLogos ref={footerLogosRef}>
          <a href="https://www.shopoff.com/" target="_blank" rel="noreferrer">
            <img src={logo3} alt="ShopOff" />
          </a>
          <a
            href="https://polarispacific.com/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={logo1} alt="Polaris Pacific" />
          </a>
        </FooterLogos>
      </FooterColumn>
    </FooterWrapper>
  );
};

export default Footer;
